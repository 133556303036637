import React from "react"
import Layout from "../components/layout"
import { StaticQuery, graphql } from "gatsby"
import { rhythm } from "../utils/typography"
import Image from "gatsby-image"
import checkListImage from "../../content/assets/short-story-checklist.jpg";
import "./checklist.css";

//<style type="text/css">
  //                          #mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }
	///* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
	  // We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
//</style> */}
//<link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css" />
//<script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';fnames[6]='SOURCE';ftypes[6]='dropdown';}(jQuery));var $mcj = jQuery.noConflict(true);</script>

class Checklist extends React.Component {
    render() {

        return (
            <Layout location={this.props.location} title={null}>
                <div
                    style={{
                        display: `flex`,
                        flexDirection: "column",
                        alignItems: "center",
                        marginBottom: rhythm(2.5),
                        width: "100%"
                    }}
                >
                    <img src={checkListImage} alt="Get our short story checklist" />
                    <div>
                        <h3>This checklist includes:</h3>
                        <ul>
                            <li>The 3 simple rules of short stories</li>
                            <li>Why you need to follow them</li>
                            <li>And, how you can apply them in your story</li>
                        </ul>
                    </div>
                    <div>
                        <div id="mc_embed_signup">
                            <form action="https://derrickpersson.us17.list-manage.com/subscribe/post?u=1aadc88bc392f336d7927a4f1&amp;id=f06f0d1071" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" novalidate>
                                <div id="mc_embed_signup_scroll">
                                    <div class="mc-field-group">
                                        <label for="mce-FNAME">First Name </label>
                                        <input type="text"  name="FNAME" class="" id="mce-FNAME"/>
                                    </div>
                                    <div class="mc-field-group">
                                        <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
                                        </label>
                                        <input type="email"  name="EMAIL" class="required email" id="mce-EMAIL"/>
                                        <div class="indicates-required"><span class="asterisk">*</span> indicates required
                                        </div>
                                    </div>
                                </div>
                                <div class="mc-submit-group">
                                    <select name="SOURCE" class="" id="mce-SOURCE" value="writer" style={{ display: "none" }}>
                                        <option value="writer">writer</option>
                                    </select>
                                    <div id="mce-responses" class="clear">
                                        <div class="response" id="mce-error-response" style={{ display: "none" }}></div>
                                        <div class="response" id="mce-success-response" style={{ display: "none" }}></div>
                                    </div>
                                    <div style={{position: "absolute", left: "-5000px" }} aria-hidden="true"><input type="text" name="b_dd459cae387a2a9c7e55ae565_90b9d71af9" tabindex="-1" value=""/></div>
                                    <div class="clear"><input type="submit" value="Give me the checklist!*" name="subscribe" id="mc-embedded-subscribe" class="button" /></div>
                                </div>
                                <div>*You'll also be signed up to receive my newsletter where I send out other goodies periodically!</div>
                            </form>              
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default Checklist
